import React, { Component } from 'react';
import WalkThroughCompositionImage from './assets/walkthroughcomposition.png';
import WalkThroughCompositionVideo from './assets/walkthroughcomposition.mp4';

import Page from '../../components/Page';

import {
    FirstColumn,
    StyleImage,
    StyleVideo,
} from '../../styles/page';

export default class WalkThroughComposition extends Component {
    static title = 'Walkthrough Composition';
    static date = 20180219;
    static description = 'Walkthrough Composition offers an interactive musical experience that alters based on the user\'s position.';
    static imagePath = WalkThroughCompositionImage;

    render() {
        const VideoItem = () => <StyleVideo controls><source src={WalkThroughCompositionVideo} type='video/mp4' /></StyleVideo>;

        const content = (
            <FirstColumn>
                <h1>{WalkThroughComposition.title}</h1>
                <p>{WalkThroughComposition.description}</p>
                <StyleImage src={WalkThroughComposition.imagePath} />
                <p>
                    The composition features varying levels of intensity, ranging from darker to lighter musical tones. The controller utilizes a Teensy 3.2. Visual graphics were crafted in Processing while the music composition was developed using Max and Ableton Live.
                </p>
                <p>
                    The joystick depicted in the following video controls the composition.
                </p>
                <VideoItem />
                <p>
                    This interactive piece was showcased at the Muziekhuis in Utrecht.
                </p>
            </FirstColumn>
        );

        return (
            <Page title={WalkThroughComposition.title} content={content} />
        );
    }
}
