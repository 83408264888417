import React, { Component } from 'react'
import LfoBlenderImage from './assets/lfo-blend.jpg'
import LfoBlenderAudioExample from './assets/lfo-blender-test.wav'

import Page from '../../components/Page';

import {
    FirstColumn,
    StyleImage,
    StyleAudio,
} from '../../styles/page'

export default class LfoBlender extends Component {
    static title: string = 'LFO Blender';
    static date: number = 20190912;
    static description: string = 'LFO Blender Control Voltage LV2 plugin';
    static imagePath: string = LfoBlenderImage;

    render() {
        const AudioItem = () => <StyleAudio controls><source src={LfoBlenderAudioExample} type='audio/wav' /></StyleAudio>;
        const content =
        <FirstColumn>
            <h1>{LfoBlender.title}</h1>
            <br />
            <p>{LfoBlender.description}</p>
            <br />
            <StyleImage src={LfoBlender.imagePath} />
            <h3>Features</h3>
            <br />
            <p>
                The CV-LFO-Blender plugin features four LFOs. Each LFO allows waveform selection and independent phase offset adjustment. A global blend control enables LFO mixing. The plugin provides five CV outputs: one for the combined LFO mix and one for each individual LFO.
            </p>
            <br />
            <p>
                Additionally, it includes three CV inputs: one for blend knob modulation, one for LFO frequency control, and another to reset the LFOs' phase.
            </p>
            <br />
            <p>
                The source code can be found <a href='https://github.com/BramGiesen/cv-lfo-blender-lv2'>here</a>.
            </p>
            <br />
            <h3>Audio Examples</h3>
            <p>
                Below is an example using the LFO Blender to modulate a filter's frequency:
            </p>
            <AudioItem />
        </FirstColumn>

        return (
            <Page title={LfoBlender.title} content={content} />
        )
    }
}
