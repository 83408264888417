import React, { useEffect, useState } from 'react';

import { useSmallScreen } from '../../context/ScreenSize';

import {
    WalkThroughComposition,
    RingMod,
    LfoBlender,
    BelaPedal,
    GuitarSynth,
    FourGen,
    Arpeggiator,
    ModCvPlugins,
    SoulRush,
    TransientMangler,
    IrregularBeatGenerator,
} from '../../pages';


import Card from '../../components/Card';
import TopBlog from '../../components/TopBlog';
import Page from '../../components/Page';
import rotatingTextColor from '../../components/RotatingTextColor'

function translateDate(date: number): string {
    const datestring: string = date.toString();
    const year = datestring.slice(0, 4);
    const month = datestring.slice(4,6);
    const day = datestring.slice(6,8);

    return `${year}-${month}-${day}`;
}

function Home() {
    const { isSmallScreen, updateScreenSize } = useSmallScreen();

    const pageMap = [
    { name: 'TransientMangler', component: TransientMangler },
    { name: 'Arpeggiator', component: Arpeggiator },
    { name: 'LfoBlender', component: LfoBlender },
    { name: 'ModCvPlugins', component: ModCvPlugins },
    { name: 'SoulRush', component: SoulRush },
    { name: 'RingMod', component: RingMod },
    { name: 'BelaPedal', component: BelaPedal },
    { name: '4Gen', component: FourGen },
    { name: 'GuitarSynth', component: GuitarSynth },
    { name: 'IrregularBeatGenerator', component: IrregularBeatGenerator },
    { name: 'WalkThroughComposition', component: WalkThroughComposition },
    ];

    const pageList = [
        TransientMangler,
        Arpeggiator,
        LfoBlender,
        ModCvPlugins,
        SoulRush,
        RingMod,
        BelaPedal,
        FourGen,
        GuitarSynth,
        IrregularBeatGenerator,
        WalkThroughComposition
    ];

    pageList.sort(function (a, b) {
        const dateA = new Date(a.date), dateB = new Date(b.date);
        return (dateB as any) - (dateA as any);
    });

    useEffect(() => {
        document.title = 'Bram Giesen';
    });

    let first: boolean = true;
    let cardIdx: number = 0;

    let dark = false;

    const Cards = pageMap.map((page) => {
        const component = page.component;

        const datestring: string = translateDate(component.date);
        const color = rotatingTextColor(cardIdx);
        cardIdx += 1;

        if (first) {
            first = false;
            if (!isSmallScreen) {
                return <TopBlog key={component.title} title={component.title} date={datestring} description={component.description} image={component.imagePath} dateColor={color} link={`/${page.name}`} />
            }
        }
        return <Card key={component.title + cardIdx.toString()} title={component.title} date={datestring} description={component.description} image={component.imagePath} titleColor={color} link={`/${page.name}`} />;
    });

    return (
        <Page title='Bram Giesen' content={Cards as any} home={true} smallScreen={isSmallScreen} />
    );
}

export default Home;
