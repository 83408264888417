import styled from "styled-components";
import{ device } from './device'

export const SiteBody = styled.div`
    height: 100%;
    align-items: center;
    text-align: center;
    margin: auto;
    max-width: 1600px !important;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    flex-wrap: wrap;
    background-color: white;
`

export const Grid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 70% auto;
    @media ${device.mobile} {
        grid-template-columns: 100%;
    }
`

export const FirstColumn = styled.div`
    margin-bottom: 5%;
    margin-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
    max-width: 1030px;
    text-align: left;
`
export const SecondColumn = styled(FirstColumn)`
    margin-top: 25% !important;
    text-align: left;
    background-color: #ebebeb;
`

export const StyleH3 = styled.h3`
    margin-top: 2%;
    margin-bottom: 2%;
`

export const StyleLi = styled.li`
    margin-top: 10px;
    margin-bottom: 10px;
`

export const StyleUl = styled.ul`
    margin-left: 5%;
`

export const StyleVideo = styled.video`
    margin-top: 10px;
    margin-bottom: 10px;
    width: 95%;
    height: auto;
`

export const StyleAudio = styled.audio`
    margin-top: 10px;
    margin-bottom: 10px;
    width: 95%;
`

export const StyleImage = styled.img`
    margin-top: 10px;
    margin-bottom: 10px;
    width: 95%;
    height: auto;
`

export default SiteBody;
