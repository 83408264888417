import React from 'react';
import styled from "styled-components";
import GitHubLogo from '../assets/images/GitHub-Mark-64px.png'
import LinkedinLogo from '../assets/images/LI-In-Bug.png'


const FooterStyle = styled.div`
    display: grid;
    grid-template-columns: 100%;
    margin-top: auto;
    width: 100%;
    height: 50px;
    background-color: lightgray;
`

const Seperator = styled.div`
    width: 100%;
    height: 50px;
    background-color: white;
`

const Content = styled.div`
    display: grid;
    grid-template-columns: 50% auto;
    width: 100%;
    height: 50px;
    background-color: lightgray;
    align-items: center;
`

const StyledH4 = styled.h4`
    margin-left: 10px;
    text-align: left;
    color: #212121;
`

const StyledLogo = styled.img`
    height: auto;
    width: 25px;
    margin-left: 15px;
    justify-items: center;
`

const Logos = styled.div`
    text-align: right !important;
    margin-right: 10px;
`

const StyledLinkedinLogo = styled(StyledLogo)`
    width: 30px !important;
`

export default function Footer() {

    const currentYear = new Date().getFullYear();

    return (
        <FooterStyle>
        <Seperator />
        <Content>
            <StyledH4>
                Copyright © Bram Giesen {currentYear}
            </StyledH4>
            <Logos>
                <a href='https://github.com/BramGiesen'>
                <StyledLogo src={GitHubLogo} alt='githublogo' />
                </a>
                <a href='https://linkedin.com/in/bram-giesen-780634162'>
                <StyledLinkedinLogo src={LinkedinLogo} alt='githublogo' />
                </a>
            </Logos>
        </Content>
        </FooterStyle>
  )
}
