import React from 'react';
import styled from "styled-components";

type DarkProps = {
  smallScreen: boolean;
}

const StyledHref = styled.a<DarkProps>`
  text-decoration: none;
  color: ${({smallScreen}) => 
    smallScreen && 'white' || 'black'
  };
  &:hover {
    background-color: lightgray;
    color: #0034e0;
  }
`

const Nav = styled.div<DarkProps>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  justify-content: space-between;
  align-items: center;
  background-color: ${({smallScreen}) => 
    smallScreen && 'black' || 'white'
  };
  margin-bottom: ${({smallScreen}) => 
    smallScreen && '20px' || '0px'
  };
`

type NavBarProps = {
  home?: boolean,
  smallScreen?: boolean,
}

export default function Navbar({ home, smallScreen }:NavBarProps) {

  let title: string = ''
  if (home) {
    title = 'Bram Giesen.'
  } else {
    title = '< Back'
  }

  return (
    <Nav smallScreen={smallScreen as any}>
      <StyledHref href="/" className="home Link" smallScreen={smallScreen as any}>
        <h1>{title}</h1>
      </StyledHref>
    </Nav>
  )
}
