import React, { Component } from 'react'
import BelaPedalImage from './assets/belapedal.png'
import BelaPedalVideo from './assets/belapedal.mp4'

import Page from '../../components/Page';

import {
    FirstColumn,
    StyleVideo,
    StyleImage,
} from '../../styles/page'

export default class BelaPedal extends Component {
    static title: string = 'Bela Pedal';
    static date: number = 20180701;
    static description: string = 'For this project I made my own stompbox with a Bela inside.';
    static imagePath: string = BelaPedalImage;

    render() {
        const VideoItem = () => <StyleVideo controls><source src={BelaPedalVideo} type='video/mp4' /></StyleVideo>;
        const content =
            <FirstColumn>

            <div>
                <h1>{BelaPedal.title}</h1>
            </div>

            <p> {BelaPedal.description} </p>

            <StyleImage src={BelaPedalImage} alt='BelaImg'/>

            <p>
            For this project, I made my own stompbox with a <a href='https://bela.io'>Bela</a> inside.
            </p>
            <br />
            <p>
            The effect that is used in the demo video below is a ring modulation effect. This effect uses the same code as the ringmodulator plugin I have made.
            </p>
            <VideoItem />
            <p>
            <a href='https://github.com/BramGiesen/Bela-Project-/tree/master/code'>click here</a> for source code.
            </p>
        </FirstColumn>

        return (
            <Page title={BelaPedal.title} content={content} />
        )
    }
}
