import React from 'react'
import { Link } from "react-router-dom";
import styled from "styled-components";

const CardStyle = styled(Link)`
  color: black;
  text-align: left;
  width: 40%;
  margin: 1%;
  min-width: 500px;
  min-height: 500px;
  @media (max-width: 600px) {
    min-height: 0px;
    min-width: 400px;
    margin-bottom: 40px;
  }
  @media (max-width: 400px) {
    min-height: 0px;
    min-width: 300px;
    margin-bottom: 40px;
  }
  @media (max-width: 300px) {
    min-height: 0px;
    min-width: 200px;
    margin-bottom: 40px;
  }
`

const DateStyle = styled.h4`
  margin-top: 2%;
  margin-bottom: 2%;
`

const CardImage = styled.img`
  width: 100%;
`

const ParStyle = styled.p`
  color: #505050;
`

type CardProps = {
  title: string,
  date: string,
  description: string,
  image: string,
  titleColor: string,
  link: string,
}

export default function Card({title, date, description, image, titleColor, link}:CardProps) {
  return (
    <CardStyle to={link}>
      <CardImage src={image} />
      <h1>{title}</h1>
      <DateStyle style={{color: titleColor}}>{date}</DateStyle>
      <ParStyle>{description}</ParStyle>
    </CardStyle>
  )
}
