import React, { Component } from 'react'
import RingModImage from './assets/ringmod-vst.png'
import RingModAudio1 from './assets/effect_bounce.mp3'
import RingModAudio2 from './assets/effect_chaos.mp3'

import Page from '../../components/Page';

import {
    FirstColumn,
    StyleImage,
    StyleAudio,
} from '../../styles/page'

export default class RingMod extends Component {
    static title: string = 'Ring Modulator VST';
    static date: number = 20181101;
    static description: string = 'VST/AU ring modulator featuring an LFO that controls the frequency of the modulator.';
    static imagePath: string = RingModImage;

    render() {
        const AudioItem1 = () => <StyleAudio controls><source src={RingModAudio1} type='audio/mp3' /></StyleAudio>;
        const AudioItem2 = () => <StyleAudio controls><source src={RingModAudio2} type='audio/mp3' /></StyleAudio>;

        const content =
        <FirstColumn>
            <h1>{RingMod.title}</h1>
            <br />
            <p>{RingMod.description}</p>
            <br />
            <StyleImage src={RingMod.imagePath} />
            <br />
            <p>
                The LFO offers several waveform options including: saw wave, inverted saw wave, sine, square, and a noise (random) oscillator.
            </p>
            <p>
                Additionally, the LFO can be smoothed using the GLIDE knob. The VST/AU is developed in C++ and utilizes the JUCE framework.
            </p>
            <br />
            <p>
                View the source code <a href='https://github.com/BramGiesen/ringmodulator_VST-AU'>here</a>.
            </p>
            <br />
            <h3>Audio Examples</h3>
            <AudioItem1 />
            <AudioItem2 />
        </FirstColumn>

        return (
            <Page title={RingMod.title} content={content} />
        )
    }
}
