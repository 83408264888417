import React, { Fragment } from 'react';
import ScrollToTop from './components/ScrollToTop';
import { SmallScreenProvider } from './context/ScreenSize';

import {
  Home,
  WalkThroughComposition,
  RingMod,
  LfoBlender,
  BelaPedal,
  GuitarSynth,
  FourGen,
  Arpeggiator,
  ModCvPlugins,
  SoulRush,
  TransientMangler,
  IrregularBeatGenerator,
} from './pages';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

function App() {

  return (
     <>
     <SmallScreenProvider>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/TransientMangler" element={<TransientMangler />} />
          <Route path="/Arpeggiator" element={<Arpeggiator />} />
          <Route path="/LfoBlender" element={<LfoBlender />} />
          <Route path="/ModCvPlugins" element={<ModCvPlugins />} />
          <Route path="/SoulRush" element={<SoulRush />} />
          <Route path="/RingMod" element={<RingMod />} />
          <Route path="/BelaPedal" element={<BelaPedal />} />
          <Route path="/4Gen" element={<FourGen />} />
          <Route path="/GuitarSynth" element={<GuitarSynth />} />
          <Route path="/IrregularBeatGenerator" element={<IrregularBeatGenerator />} />
          <Route path="/WalkThroughComposition" element={<WalkThroughComposition />} />
        </Routes>
      </Router>
      </SmallScreenProvider>
    </>
  );
}

export default App;
