import React, { Component } from 'react'
import TransientManglerImage from './assets/transientMangler.jpg'
import TransientManglerAudio from './assets/transientManglerDemo.wav'

import Page from '../../components/Page';

import {
    FirstColumn,
    StyleImage,
    StyleAudio,
} from '../../styles/page'

export default class TransientMangler extends Component {
    static title: string = 'Transient Mangler';
    static date: number = 20210604;
    static description: string = 'The Transient Mangler plugin alters the pitch of the incoming signal without compromising its rhythmic properties.';
    static imagePath: string = TransientManglerImage;

    render() {
        const AudioItem = () => <StyleAudio controls><source src={TransientManglerAudio} type='audio/wav' /></StyleAudio>;

        const content =
        <FirstColumn>
            <h1>{TransientMangler.title}</h1>
            <br />
            <p>{TransientMangler.description}</p>
            <br />
            <StyleImage src={TransientMangler.imagePath} />
            <br />
            <p>
                The plugin achieves its unique effect by integrating an onset detection algorithm with an interpolating buffer. While it's versatile, its design primarily caters to the manipulation of percussive sounds, such as drums.
            </p>
            <br />
            <p>
                One notable feature is the ADSR envelope, which activates with each transient. By fine-tuning its parameters, users can craft sharp and concise percussive tones.
            </p>
            <p>
                The Transient Mangler is a collaborative creation involving <a href='http://geertroks.com'>Geert Roks</a>, Haider Raja, and <a href='http://wardslager.com'>Ward Slager</a>.
            </p>
            <br />
            <h3>Audio Examples</h3>
            <p>
                The audio below demonstrates how the Transient Mangler can modulate the pitch and envelope of a drum loop:
            </p>
            <AudioItem />
        </FirstColumn>
        return (
            <Page title={TransientMangler.title} content={content} />
        )
    }
}
