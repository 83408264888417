import React, { Component } from 'react'
import GuitarSynthImage from './assets/guitarsynth-vst.png'
import GuitarSynthVideo from './assets/guitar_synth.mp4'

import Page from '../../components/Page';

import {
    FirstColumn,
    StyleImage,
    StyleVideo,
} from '../../styles/page'

export default class GuitarSynth extends Component {
    static title: string = 'Guitar Synth';
    static date: number = 20180607;
    static description: string = 'Guitar Synth VST using JUCE';
    static imagePath: string = GuitarSynthImage;

    render() {
        const VideoItem = () => <StyleVideo controls><source src={GuitarSynthVideo} type='video/mp4' /></StyleVideo>;
        const content =
        <FirstColumn>
            <h1>{GuitarSynth.title}</h1>
            <br />
            <p>{GuitarSynth.description}</p>
            <br />
            <StyleImage src={GuitarSynth.imagePath} />
            <br />
            <p>
                This plugin uses real-time pitch tracking to control an Oscillator, an envelope follower, and a vocoder.
            </p>
            <br />
            <p>
                This is a small demonstration video of what the plugin sounds
                like:
            </p>
            <VideoItem />
        </FirstColumn>
        return (
            <Page title={GuitarSynth.title} content={content} />
        )
    }
}
