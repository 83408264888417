import {createGlobalStyle} from "styled-components"

export default createGlobalStyle`
  body {
    background-color: #F3F3F3;

  }
   *{
       text-decoration: none;
       margin: 0;
       padding: 0;
       outline:0;
       box-sizing:border-box;
   }
   #root{
       margin:0 auto;
   }
`