import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';

interface SmallScreenContextType {
    isSmallScreen: boolean;
    updateScreenSize: () => void;
}

export const SmallScreenContext = createContext<SmallScreenContextType | undefined>(undefined);

export const useSmallScreen = () => {
    const context = useContext(SmallScreenContext);
    if (!context) {
        throw new Error('useSmallScreen must be used within a SmallScreenProvider');
    }
    return context;
};

interface Props {
    children: ReactNode;
}

export const SmallScreenProvider: React.FC<Props> = ({ children }) => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1060); // assuming 1040px is your breakpoint for a small screen

    const updateScreenSize = () => {
        setIsSmallScreen(window.innerWidth <= 1060);
    };

    useEffect(() => {
        window.addEventListener('resize', updateScreenSize);
        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateScreenSize);
        };
    }, []);

    return (
        <SmallScreenContext.Provider value={{ isSmallScreen, updateScreenSize }}>
            {children}
        </SmallScreenContext.Provider>
    );
};
