import React, { Component } from 'react'
import IrregularBeatGeneratorImage from './assets/irregularbeat.png'
import Beat1 from './assets/DRUMBEAT.wav'
import Beat2 from './assets/DRUMBEAT_5-4.wav'

import Page from '../../components/Page';

import {
    FirstColumn,
    StyleImage,
    StyleUl,
    StyleLi,
    StyleAudio,
} from '../../styles/page'

export default class IrregularBeatGenerator extends Component {
    static title: string = 'Irregular Beat Generator';
    static date: number = 20180208;
    static description: string = 'The Irregular Beat Generator is a command line program that generates beats in odd time signatures.';
    static imagePath: string = IrregularBeatGeneratorImage;

    render() {
        const AudioItem1 = () => <StyleAudio controls><source src={Beat1} type='audio/wav' /></StyleAudio>;
        const AudioItem2 = () => <StyleAudio controls><source src={Beat2} type='audio/wav' /></StyleAudio>;

        const content =
        <FirstColumn>

            <h1>{IrregularBeatGenerator.title}</h1>
            <br />
            <p>
                {IrregularBeatGenerator.description}
            </p>
            <StyleImage src={IrregularBeatGenerator.imagePath} alt='irreg' />
            <br />

            <div>
                The program offers the following options:
               <StyleUl>
                    <StyleLi>
                        Choose between two time signatures: 5/4 and 7/8.
                    </StyleLi>
                    <StyleLi>
                        Set the BPM.
                    </StyleLi>
                    <StyleLi>
                        Opt for a beat with only quarter notes for basic rhythms or include both quarter and eighth notes for more complexity.
                    </StyleLi>
                    <StyleLi>
                        Select between electronic or acoustic drum sounds for playback.
                    </StyleLi>
                    <StyleLi>
                        Save the generated beat as a MIDI file.
                    </StyleLi>
               </StyleUl>
            </div>

            <br />
            <div>
                <div>
                Drum beat example in 7/8:
                </div>
                <AudioItem1 />
                <div>
                Drum beat example in 5/4:
                </div>
                <AudioItem2 />
            </div>
        </FirstColumn>

        return (
            <Page title={IrregularBeatGenerator.title} content={content} />
        )
    }
}
