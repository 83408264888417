const titleColors = [
    '#FF00C2',
    '#FED134',
    '#00A2B0',
];


export default function rotatingTextColor(cardIdx: number) {
    const color = titleColors[cardIdx % titleColors.length];
    return color;
}
