import React from 'react'
import styled from "styled-components";

const FlipCardStyle = styled.div`
  position: relative;
  background-color: transparent;
  width: 305px;
  height: 500px;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 12px;
  text-align: left;
`

const FlipCardBack = styled.div`
  width: 100%;
  height: 100%;
  background-color: #262626;
`

const FlipCardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #262626;
  color: black;
`

const Wrapper = styled.div`
  &:hover ${FlipCardFront} {
    display: none;
  }
`

const StyledH4 = styled.h4`
    color: lightgray;
    margin-right: 4%;
    margin-left: 4%;
`

const StyledH1 = styled.h1`
    margin-right: 4%;
    margin-left: 4%;
`

const StyledImg = styled.img`
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`


type FlipCardProps = {
    image: string,
    name: string,
    description: string,
    titleColor: string,
}

export default function FlipCard({image, name, description, titleColor}:FlipCardProps) {
        return (
            <Wrapper>
                <FlipCardStyle>
                   <FlipCardFront>
                       <StyledImg src={image} alt="plug" />
                   </FlipCardFront>
                   <FlipCardBack>
                       <br />
                       <StyledH1 style={{color: titleColor}}>{name}</StyledH1>
                       <br />
                       <StyledH4>{description}</StyledH4>
                   </FlipCardBack>
                </FlipCardStyle>
            </Wrapper>
        )
}
