import React, { Component } from 'react'
import SoulRushImage from './assets/soulrush.png'
import SoulRushVideo from './assets/soulrush.mp4'

import Page from '../../components/Page';

import {
    FirstColumn,
    StyleImage,
    StyleVideo,
} from '../../styles/page'

export default class SoulRush extends Component {
    static title: string = 'Soul Rush: Analogue Techno & Acid House';
    static date: number = 20190722;
    static description: string = 'A drum sampler plugin designed for the MOD platform.';
    static imagePath: string = SoulRushImage;

    render() {
        const VideoItem = () => <StyleVideo controls><source src={SoulRushVideo} type='video/mp4' /></StyleVideo>;

        const content =
        <FirstColumn>
            <h1>{SoulRush.title}</h1>
            <br />
            <p>{SoulRush.description}</p>
            <br />
            <StyleImage src={SoulRush.imagePath} />
            <br />
            <p>
                This plugin was created in partnership with Katarina Holmberg (Tigerlight) and her sample label, Soul Rush. The "Analogue Acid House & Techno" sample player provides five tracks of premium-quality Acid House & Techno one-shot drum samples. Experience the warmth of analog sound and finely adjust each track's settings to tailor every beat to your preferences. The plugin also boasts an optional LFO linked to sample selection, granting your loops an added layer of dynamism and depth.
            </p>
            <br />
            <p>
                Here's a brief demonstration video of the plugin in action:
            </p>
            <VideoItem />
        </FirstColumn>

        return (
            <Page title={SoulRush.title} content={content} />
        )
    }
}
