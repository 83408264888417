import React, { Fragment, useEffect } from 'react'

import { useSmallScreen } from '../context/ScreenSize';

import CreateGlobalStyle from '../styles/global';
import Navbar from './Navbar';
import Footer from './Footer';
import styled from "styled-components";

const SiteBody = styled.div`
  align-items: center;
  text-align: center;
  margin: auto;
  max-width: 1600px !important;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  background-color: white;
`

type PageContent = {
    title: string,
    content: any,
    home?: boolean,
    smallScreen?: boolean,
}

export default function Page({ title, content, home, smallScreen }: PageContent) {
    const { isSmallScreen, updateScreenSize } = useSmallScreen();

    useEffect(() => {
      document.title = title;
    });

    return (
    <Fragment>
      <CreateGlobalStyle />
      <SiteBody>
        <Navbar home={home} smallScreen={isSmallScreen}/>
        {content}
        <Footer />
      </SiteBody>
    </Fragment>
    )
}
