import React, { Component } from 'react'
import ArpeggiatorImage from './assets/arpeggiator.png'
import ArpVideo from './assets/Arp.mp4';

import Page from '../../components/Page';

import {
    FirstColumn,
    StyleH3,
    StyleLi,
    StyleUl,
    StyleVideo,
    StyleImage,
} from '../../styles/page'

export default class Arpeggiator extends Component {
    static title: string = 'Arpeggiator';
    static date: number = 20200912;
    static description: string = 'This arpeggiator is a LV2 plugin, made for the MOD platform. The GUI design is made by Andrè Fernandes.';
    static imagePath: string = ArpeggiatorImage;

    render() {

        const VideoItem = () => <StyleVideo controls><source src={ArpVideo} type='video/mp4' /></StyleVideo>;
        const content =
            <FirstColumn>
                <h1>{Arpeggiator.title}</h1>

                <br />
                <StyleImage src={ArpeggiatorImage} alt="" />

                <br />

                <p>
                {Arpeggiator.description}
                </p>
                <br />

                <StyleH3>MIDI-pattern</StyleH3>
                The MIDI-pattern plugin is part of the arpeggiator project and can be used to create rhythmic
                patterns. The plugin takes the incoming note and replaces
                the velocity of the note by a value which is set by one of the
                faders of the plugin. Because the plugin iterates through
                the faders, it generates a sort of rhythmic sequence. The CV control of the plugin
                can be used to retrigger the sequence.

                <StyleH3>Tempo options</StyleH3>
                <StyleUl>
                    <StyleLi>
                        There are a few options to set the tempo of the plugin. The first one is
                        the <b>Free Running</b> mode. In this mode, the
                        tempo is controlled by the <b>BPM</b> control of the plugin.
                    </StyleLi>
                    <StyleLi>
                        On top of the <b>BPM</b> control, there is a <b>Divisions</b> control.
                    </StyleLi>
                    <StyleLi>
                        The plugin can also be synced to the host.
                    </StyleLi>
                </StyleUl>

                <StyleH3>Arpeggiator modes</StyleH3>
                <StyleUl>
                    <StyleLi>Up</StyleLi>
                    <StyleLi>Down</StyleLi>
                    <StyleLi>Up-Down</StyleLi>
                    <StyleLi>Up-Down(alternative)</StyleLi>
                    <StyleLi>Played</StyleLi>
                    <StyleLi>Random</StyleLi>
                </StyleUl>

                <StyleH3>Octave Modes</StyleH3>

                <p>
                The arpeggiator has <b>octave spread</b> control.
                The arpeggiator can iterate the incoming notes over multiple octaves.
                This control sets the range of the octaves that will be iterated over the
                original pitch. The way how these octaves will be added to the original notes
                is determined by the <b>octave mode</b> control.
                </p>
                <br />
                <StyleH3>Demo</StyleH3>
                <p>
                Below is a small demo video of the arpeggiator. The synth that is used is the
                Behringer Neutron in combination with the Shiroverb-mkII plugin on the DUO X.
                </p>

                <VideoItem />

                <StyleH3>Caveats</StyleH3>
                <p>
                    The plugins can be used outside of the MOD ecosystem. But
                    because they are developed for usage inside the MOD, there
                    might be things that won't work properly.
                </p>
                <p>
                    The plugins contain LV2 CV ports; this feature is not supported by all
                    plugin hosts (yet).
                </p>
                <br />
                <p>
                    The source code of the most recent version can be found <a href='https://github.com/BramGiesen/bgsn-arpeggiator'>here.</a>
                </p>

            </FirstColumn>

        return (
            <Page title={Arpeggiator.title} content={content} />
        )
    }
}
