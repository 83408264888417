import React, { Component } from 'react'
import ModCvPluginsImage from './assets/mod-cv-plugins.png'
import FlipCard from '../../components/FlipCard'
import styled from "styled-components";

import rotatingTextColor from '../../components/RotatingTextColor'

import Page from '../../components/Page';

import {
    Attenuverter,
    AudioToCv,
    ControlToCv,
    CvAbs,
    CvClock,
    CvMeter,
    CvRangeDivider,
    CvRound,
    LogicOperators,
    MidiToCvMono,
    MidiToCvPoly,
    ModCvGate,
    RandomGenerator,
    SlewRateLimiter,
} from './assets'


const pluginList = [
    {
        name: 'MIDI to CV mono',
        description: 'Converts a MIDI signal to a monophonic pitch signal using 1V per octave.',
        image: MidiToCvMono,
    },
    {
        name: 'MIDI to CV poly',
        description: 'Converts a MIDI signal to 4 voices using 1V per octave.',
        image: MidiToCvPoly,
    },
    {
        name: 'CV attenuverter',
        description: 'Attenuates a signal within a range of -10 to +10. Features both logarithmic and linear modes with optional parameter smoothing.',
        image: Attenuverter,
    },
    {
        name: 'CV clock',
        description: 'Control Voltage Clock outputs pulse and square wave signals. It can be beat and phase synced to the plugin host.',
        image: CvClock,
    },
    {
        name: 'CV Abs',
        description: 'Converts CV signal to its absolute value.',
        image: CvAbs,
    },
    {
        name: 'CV Gate',
        description: 'Control Voltage Clock outputs pulse and square wave signals. It can be beat and phase synced to the plugin host.',
        image: ModCvGate,
    },
    {
        name: 'Control to CV',
        description: 'Generates a steady CV signal set by a plugin parameter. Features optional parameter smoothing.',
        image: ControlToCv,
    },
    {
        name: 'CV meter',
        description: 'Displays the value of the incoming CV signal.',
        image: CvMeter,
    },
    {
        name: 'CV to Audio',
        description: 'Converts a CV signal to audio with options to attenuate the input and a DC blocker for speaker output.',
        image: AudioToCv,
    },
    {
        name: 'Slew rate limiter',
        description: 'Regulates the rate of signal rise and fall. Ideal for glide effects on oscillator pitch. Co-developed with Ward Slager.',
        image: SlewRateLimiter,
    },
    {
        name: 'CV round',
        description: 'Manipulates the incoming signal in several ways. Outputs include rounded, ceiling, floor, and fractional values of the input.',
        image: CvRound,
    },
    {
        name: 'CV range',
        description: 'Has one input and two outputs. Routes the input to one of the outputs based on a predefined range.',
        image: CvRangeDivider,
    },
    {
        name: 'CV random',
        description: 'Generates a random value within a specified range upon receiving a gate input trigger.',
        image: RandomGenerator,
    },
    {
        name: 'Audio to CV',
        description: 'Converts audio to Control Voltage with options for attenuation and offset.',
        image: AudioToCv,
    },
    {
        name: 'CV logic operators',
        description: 'Logic operator plugin featuring switch point and hysteresis controls. Operators include AND, NAND, INV, OR, NOR, XOR, and XNOR.',
        image: LogicOperators,
    },
]


export default class ModCvPlugins extends Component {
    static title: string = 'MOD CV Plugins';
    static date: number = 20191209;
    static description: string = 'A collection of Control Voltage plugins made for MOD Devices.';
    static imagePath: string = ModCvPluginsImage;

    render() {

        const Grid = styled.div`
            margin-top: 5%;
            display: grid;
        `

        // TODO set width globally
        const Colummn = styled.div`
            margin-top: 5%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            max-width: 1030px;
            flex-wrap: wrap;
        `
        let pluginIdx: number = 0;

        const PluginCards = pluginList.map((plugin) => {

            const color = rotatingTextColor(pluginIdx);
            pluginIdx += 1;
            return <FlipCard key={plugin.name} image={plugin.image} name={plugin.name} description={plugin.description} titleColor={color} />;
        });

        const content =
        <Grid>
        <div>
        <h1>{ModCvPlugins.title}</h1>
        <br />
        <p>
            {ModCvPlugins.description}
        </p>
        </div>
        <Colummn>
        {PluginCards}
        </Colummn>
        </Grid>

        return (
            <Page title={ModCvPlugins.title} content={content} />
        )
    }
}
