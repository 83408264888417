import React, { Component } from 'react'
import FourGenImage from './assets/4gen.png'

import Page from '../../components/Page';

import {
    FirstColumn,
    StyleImage,
} from '../../styles/page'

export default class FourGen extends Component {
    static title: string = '4gen';
    static date: number = 20180201;
    static description: string = '4gen is an interactive sound installation. It combines dark ambient music with speeches. The intensity of the music depends on the amount of activity in the room. If the activity increases, the music becomes more extreme.';
    static imagePath: string = FourGenImage;

    render() {
        const content =
        <FirstColumn>
            <h1>{FourGen.title}</h1>
            <br />
            <p>{FourGen.description}</p>
            <br />
            <StyleImage src={FourGen.imagePath} />

            <p>
            The installation uses a webcam as a sensor. The data from the webcam is analyzed in processing and then sent to Pure Data where the sounds are generated.
            The installation was set up at the entrance of the University of Arts Utrecht.
            </p>
            <br />
            <p>
            The project was in collaboration with <a href='https://www.wardslager.com'>Ward Slager</a>, Chris Mollee, and Jacob Schwartz.
            </p>
            <br />
            <p>
            The code for the project can be found <a href='https://github.com/wjslager/4gen'>here</a>.
            </p>
        </FirstColumn>
        return (
            <Page title={FourGen.title} content={content} />
        )
    }
}
