import React from 'react'
import styled from "styled-components";
import { Link } from "react-router-dom";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 70% auto;
  @media (max-width: 1040px) {
      height: 450px;
      grid-template-columns: 100%;
  }
`

const StyledLink = styled(Link)`
  display: flex;
  flex-wrap: wrap;
  width: 82%;
  margin: 5%;
  min-width: 400px;
  background-color: lightgray;
  color: black;
  max-width: 1320px;
  min-width: 1032px;
  @media (max-width: 1040px) {
      max-width: 400px;
      min-width: 400px;
      min-height: 400px;
  }
`

const ImgStyle = styled.img`
  width: 100%;
`

const TextStyle = styled.div`
  margin-top: 2%;
  text-align: left;
  padding-left: 10px;
  padding-bottom: 10px;
  @media (max-width: 1040px) {
    padding-left: 0px !important;
  }
`

const BodyStyle = styled(TextStyle)`
  color: #505050;
`

type TopBlogProps = {
  title: string,
  date: string,
  description: string,
  image: string,
  dateColor: string,
  link: string;
}

export default function topBlog({title, date, description, image, dateColor, link}:TopBlogProps) {

  return (
    <StyledLink to={link}>
        <Grid>
          <ImgStyle src={image} />
          <TextStyle>
            <div>
              <h1>{title}</h1>
            </div>
            <br />
            <div>
            <h3 style={{color: dateColor}}>{date}</h3>
            </div>
            <br />
            <BodyStyle>
              <h4>
                {description}
              </h4>
            </BodyStyle>
          </TextStyle>
        </Grid>
    </StyledLink>
  )
}
